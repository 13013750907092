export const STATUS = {
    COMPLETE: 'COMPLETE',
    NOT_COMPLETE: 'NOT_COMPLETE',
    NOT_RECOVERABLE: 'NOT_RECOVERABLE',
    OVERRIDDEN: 'OVERRIDDEN',
};

export type PathParams = {
    id: string,
}

export type CargoResult = {
    cargoId: number;
    dataCompleteCheckResult: {
        ambientDataCompletePercentage: string;
        ambientDataCompleteStatus: string;
        executionTimestamp: string;
        internalDataCompletePercentage: string;
        internalDataCompleteStatus: string;
        packagingNumber: string;
    };
};
