import React from 'react';
import { statuses } from 'TrackAndTrace/GenericShipments/lib';
import { ColumnsType } from 'shared-components/Table/dataTypes';
import moment from 'moment';
import icons from 'shared-components/icons';
import { STATUS, CargoResult } from 'Administration/Shipments/lib';
import InternalDataCheck from './components/InternalDataCheck/InternalDataCheck';

const marginBottom = 8;

const tableColumns: (
    t,
) => ColumnsType[] = (
    t,
) => [
    {
        accessor: (row) => {
            const { dataCompleteCheck, externalId } = row;
            const isRed = dataCompleteCheck?.cargoResults?.some(
                (cargo: CargoResult) => (
                    cargo.dataCompleteCheckResult?.internalDataCompleteStatus === STATUS.NOT_RECOVERABLE
                ),
            );

            return isRed ? (
                <div style={{ color: '#B92C2C' }}>
                    {externalId}
                </div>
            ) : externalId;
        },
        dataType: 'highlightedText',
        Header: t('TRACK_AND_TRACE.ORDER'),
        id: 'externalId',
    },
    {
        accessor: ({ status }) => statuses[status],
        dataType: 'text',
        Header: t('TRACK_AND_TRACE.STATUS'),
        id: 'status',
    },
    {
        accessor: 'originAirport',
        dataType: 'text',
        Header: t('COMMON.FROM'),
    },
    {
        accessor: 'destinationAirport',
        dataType: 'text',
        Header: t('COMMON.TO'),
    },
    {
        accessor: 'dataOwner',
        dataType: 'text',
        Header: t('TRACK_AND_TRACE.TITLE_SHIPMENT_DATA_OWNER'),
    },
    {
        accessor: (row) => row?.cargo.map((item) => {
            const { dataCompleteCheckResult } = row;
            const packagingDataCompleteCheck = dataCompleteCheckResult?.dataCompleteCheckForCargos?.find(
                cargo => cargo.packagingNumber === item.packaging.serialNumber,
            );
            const isRed = packagingDataCompleteCheck
                ?.dataCompleteCheckResult
                ?.internalDataCompleteStatus === STATUS.NOT_RECOVERABLE;

            return isRed ? (
                <div style={{ color: '#B92C2C', display: 'block' }}>
                    {item.packaging.serialNumber}
                </div>
            ) : `${item.packaging.serialNumber}\n`;
        }),
        customCellStyle: {
            maxWidth: '230px',
            whiteSpace: 'pre-wrap',
        },
        dataType: 'highlightedText',
        disableSortBy: true,
        Header: t('COMMON.PACKAGINGS'),
    },
    {
        accessor: ({ dataCompleteCheck }) => (
            dataCompleteCheck?.cargoResults
                ? (
                    <InternalDataCheck
                        cargos={dataCompleteCheck?.cargoResults}
                        marginBottom={marginBottom}
                    />
                )
                : null
        ),
        dataType: 'text',
        disableSortBy: true,
        Header: t('COMMON.INTERNAL_DATA_CHECK'),
    },
    {
        accessor: ({ dataCompleteCheck }) => (
            dataCompleteCheck?.cargoResults?.map(
                ({ dataCompleteCheckResult }, i, arr) => (
                    <div
                        key={dataCompleteCheck.id}
                        style={{ marginBottom: i !== arr.length - 1 ? marginBottom : 0 }}
                    >
                        {
                            dataCompleteCheckResult?.executionTimestamp
                                ? moment(dataCompleteCheckResult.executionTimestamp).format('DD-MM-YYYY HH:mm')
                                : ' '
                        }
                    </div>
                )) || ' '
        ),
        dataType: 'text',
        disableSortBy: true,
        Header: t('COMMON.DATA_CHECK_TIMESTAMP_UTC'),
    },
    {
        accessor: ({ dataCompleteCheck }) => (
            dataCompleteCheck?.overrideOptions?.override
                ? (
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <img alt="tasks" src={icons.checked_green} style={{ marginRight: 8 }} />
                        {t('COMMON.OVERRIDDEN')}
                    </div>
                )
                : ' '
        ),
        customCellStyle: {
            alignItems: 'center',
        },
        dataType: 'text',
        Header: t('COMMON.DATA_CHECK_OVERRIDE'),
        id: 'dataCheckOverride',
    },
].filter(Boolean);

export default tableColumns;
