import useClasses from 'hooks/useClasses';
import React from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import PackagingBox from 'TrackAndTrace/GenericShipmentDetails/components/PackagingHealth/PackagingBox';
import { GenericPackaging } from 'TrackAndTrace/GenericShipmentDetails/lib';
import UncontrolledTooltip from 'shared-components/ControlledTooltip/UncontrolledTooltip';
import { GenericCargo } from 'dataTypes/SecureBackend/apiResponse/Shipment';
import { EnergyLevelStatus } from 'TrackAndTrace/lib';
import { styles } from './PackagingHealth.style';

type Props = {
    packagings: GenericPackaging[],
    cargo?: GenericCargo[]
}
const PackagingHealth = ({
    packagings = [],
    cargo = [],
}:Props) => {
    const classes = useClasses(styles);
    const { t } = useCustomTranslation();

    return (
        <div className={classes.root}>
            <div className={classes.health}>
                <div className={classes.title}>
                    {t('TRACK_AND_TRACE.PACKAGING_HEALTH')}
                </div>
                <UncontrolledTooltip
                    description={t('EXPLANATION_TOOLTIPS.PACKAGING_HEALTH_AND_STATUS')}
                    placement="left"
                    whiteSpace="pre"
                >
                    <div className={classes.packagings}>
                        {
                            packagings.map((packaging, index) => (
                                <PackagingBox
                                    key={packaging.serialNumber}
                                    packaging={packaging}
                                    cargo={cargo[index]}
                                    energyLevel={cargo[index]?.energyLevelCheckResult?.remainingEnergyLevel}
                                    hasBreach={cargo[index]
                                        ?.energyLevelCheckResult?.energyLevelStatus
                                        === EnergyLevelStatus.ENERGY_LEVEL_BREACH}
                                />
                            ))
                        }
                    </div>
                </UncontrolledTooltip>
            </div>
        </div>
    );
};

export default PackagingHealth;
