import React, {
    useEffect,
    useState, useMemo, useContext,
} from 'react';
import { Company, ContactInfo as ContactInfoType } from 'dataTypes/SecureBackend/apiResponse';
import { AddCompanyRequestBody, Address } from 'dataTypes/SecureBackend/processedData';
import useCurrentUserContext from 'hooks/useCurrentUserContext';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints';

import AddressCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/AddressCard';
import CompanyCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/CompanyCard';
// eslint-disable-next-line max-len
import ContactInformationCard from 'shared-components/CompanyInfoComponents/AdministrationCompany/ContactInformationCard';

import useHasAccess, { userRoles } from 'hooks/useHasAccess';
import NotificationChannelSettingsCard from 'MyProfile/components/NotificationChannelSettingsCard';
import BatteryExchangeLocationsCard from 'MyCompany/CompanyInfo/tabs/Overview/components/BatteryExchangeLocationsCard';
import useClasses from 'hooks/useClasses';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import { ONBOARDING_TYPE, QuickHelpContext } from 'Contexts/QuickHelpContext/QuickHelpContext';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { getQuickHelpAsset } from 'shared-components/icons';
import PharmaCompanySites from './components/PharmaCompanySites';
import ServiceProviderSites from './components/ServiceProviderSites';
import styles from './Overview.style';

const Overview = () => {
    const classes = useClasses(styles);
    const { Get: getCompany, FlexibleRequest: uploadFile } = useSecureBackendEndpoints('companies').requests;
    const { FlexibleRequest: editCompany } = useSecureBackendEndpoints('companies').statusNotificationRequests;
    const hasAccess = useHasAccess();
    const {
        setHasOnboardingTour,
        setOnboardingTooltipInfo,
    } = useContext(QuickHelpContext);
    const {
        t,
    } = useCustomTranslation();

    const [companyInfo, setCompanyInfo] = useState(null);
    const [currentEditedCard, setCurrentEditedCard] = useState<string>(null);
    const [address, setAddress] = useState<Address>(null);
    const [contactInfo, setContactInfo] = useState<ContactInfoType>(null);
    const [addCompanyRequestBody, setAddCompanyRequestBody] = useState<AddCompanyRequestBody>(null);
    const [logoFormData, setLogoFormData] = useState<FormData>(null);
    const [requiredUpdateData, setRequiredUpdateData] = useState(true);
    const hasRoles = useHasAccess();
    const {
        company,
        setCompany,
        userInfo,
    } = useCurrentUserContext();
    const [loading, setLoading] = useState(false);

    const { companyId } = userInfo;

    const handleClickEdit = async () => {
        try {
            if (logoFormData) {
                await uploadFile('POST', `/${companyId}/logo`, logoFormData);
                setLogoFormData(null);
            }
            const { status = null } = await editCompany('PATCH', `/${companyId}/overview-info`, addCompanyRequestBody);

            if (status === 200) {
                setRequiredUpdateData(true);
            }
        } catch (error) {
            global.console.log(error);
        }
    };

    useEffect(() => {
        if (address && contactInfo && companyInfo) {
            setAddCompanyRequestBody({
                ...companyInfo,
                address,
                contactInfo,
            });
        }
    }, [
        address,
        contactInfo,
        companyInfo,
    ]);

    useEffect(() => {
        setHasOnboardingTour(hasRoles(userRoles.CUSTOMER_ADMIN));
        setOnboardingTooltipInfo({
            title: t('ONBOARDING.TRACK_AND_TRACE.ONBOARDING_TICKET'),
            text: t('ONBOARDING.TRACK_AND_TRACE.ONBOARDING_TICKET_DESC'),
            order: 3,
            position: 'auto',
            padding: 0,
            core: ONBOARDING_TYPE.COMPANY,
            uid: 'supportTicketCreationQuickHelpCompany',
        });

        return () => {
            setHasOnboardingTour(false);
            setOnboardingTooltipInfo(null);
        };
    }, [hasRoles]);
    useEffect(() => {
        if (companyId && (company.id === null || requiredUpdateData)) {
            setLoading(true);
            (async () => {
                try {
                    const rawResponse = await getCompany(companyId);
                    const response: Company = rawResponse?.data || null;

                    if (response) {
                        setCompany(response);
                    }
                } catch (error) {
                    global.console.log(error);
                } finally {
                    setLoading(false);
                    setRequiredUpdateData(false);
                }
            })();
        }
    }, [companyId, requiredUpdateData]);

    useEffect(() => {
        (async () => {
            try {
                if (company.id) {
                    setContactInfo(company.contactInfo);
                    setCompanyInfo({
                        companyName: company.name,
                        companyType: company.companyType,
                        website: company.website,
                    });
                    setAddress({
                        addressLine1: company.address.addressLine1,
                        addressLine2: company.address.addressLine2,
                        addressLine3: company.address.addressLine3,
                        city: company.address.city,
                        countryCode: company.address.country.code,
                        geolocation: company.address.geolocation,
                        zipCode: company.address.zipCode,
                    });
                }
            } catch (error) {
                global.console.log(error);
            }
        })();
    }, [company]);

    const isCustomerAdmin = useMemo(() => hasAccess(userRoles.CUSTOMER_ADMIN), [hasAccess]);

    return (
        <>
            {
                company.id
                    ? (
                        <div className={classes.container}>

                            <QuickHelpTooltip
                                tooltipInfo={{
                                    text: t('ONBOARDING.MY_COMPANY_DETAILS.COMPANY_DETAILS_DESC'),
                                    title: t('ONBOARDING.MY_COMPANY_DETAILS.COMPANY_DETAILS_TITLE'),
                                    image: getQuickHelpAsset('my_company_details.gif'),
                                    order: 1,
                                    padding: 0,
                                    position: 'center',
                                    uid: 'company_details_boxes',
                                }}
                            >
                                <CompanyCard
                                    className={classes.company}
                                    currentCompanyLogo={company.logoImagePath}
                                    currentCompanyName={company.name}
                                    currentCompanyType={company.companyType}
                                    currentEditedCard={currentEditedCard}
                                    currentWebsite={company.website}
                                    type="editable"
                                    saveInfo={handleClickEdit}
                                    setCurrentEditedCard={setCurrentEditedCard}
                                    setFilter={setCompanyInfo}
                                    setLogoFormData={setLogoFormData}
                                />
                            </QuickHelpTooltip>

                            <QuickHelpTooltip
                                tooltipInfo={{
                                    order: 1,
                                    padding: 0,
                                    uid: 'address_card_onboarding',
                                }}
                            >
                                <AddressCard
                                    className={classes.address}
                                    currentAddressLine1={company.address.addressLine1}
                                    currentAddressLine2={company.address.addressLine2}
                                    currentAddressLine3={company.address.addressLine3}
                                    currentCity={company.address.city}
                                    currentZipCode={company.address.zipCode}
                                    currentCountry={company.address.country.name}
                                    currentCountryCode={company.address.country.code}
                                    currentGeolocation={company.address.geolocation}
                                    currentEditedCard={currentEditedCard}
                                    type="editable"
                                    setFilter={setAddress}
                                    saveInfo={handleClickEdit}
                                    setCurrentEditedCard={setCurrentEditedCard}
                                    isCompanyPage
                                />
                            </QuickHelpTooltip>

                            <QuickHelpTooltip
                                tooltipInfo={{
                                    order: 1,
                                    padding: 0,
                                    uid: 'contact_information_onboarding',
                                }}
                            >
                                <ContactInformationCard
                                    className={classes.contact}
                                    currentContactPerson={company.contactInfo.contactPerson}
                                    currentPhoneNumber={company.contactInfo.phoneNumber}
                                    currentEmail={company.contactInfo.email}
                                    currentEditedCard={currentEditedCard}
                                    type="editable"
                                    setFilter={setContactInfo}
                                    saveInfo={handleClickEdit}
                                    setCurrentEditedCard={setCurrentEditedCard}
                                    isCompanyPage
                                />
                            </QuickHelpTooltip>

                            {
                                company.companyType === 'PHARMA' && (hasAccess(userRoles.SITE_MANAGEMENT)) && (
                                    <QuickHelpTooltip
                                        tooltipInfo={{
                                            order: 1,
                                            padding: 0,
                                            uid: 'pharma_sites_onboarding',
                                        }}
                                    >
                                        <PharmaCompanySites
                                            className={classes.pcsTable}
                                            pharmaCompanyId={companyId}
                                        />
                                    </QuickHelpTooltip>

                                )
                            }
                            {
                                company.companyType === 'SERVICE_PROVIDER'
                                && (hasAccess(userRoles.SITE_MANAGEMENT)) && (
                                    <QuickHelpTooltip
                                        tooltipInfo={{
                                            order: 1,
                                            padding: 0,
                                            uid: 'service_provides_onboarding',
                                        }}
                                    >
                                        <ServiceProviderSites
                                            className={classes.spsTable}
                                            serviceProviderId={companyId}
                                        />
                                    </QuickHelpTooltip>

                                )
                            }
                        </div>
                    )
                    : null
            }
            {
                isCustomerAdmin && (
                    <QuickHelpTooltip
                        tooltipInfo={{
                            text: t('ONBOARDING.MY_COMPANY_DETAILS.SETTING_NOTIFICATIONS_DESC'),
                            title: t('ONBOARDING.MY_COMPANY_DETAILS.SETTING_NOTIFICATIONS_TITLE'),
                            order: 2,
                            padding: 0,
                            position: 'top',
                            uid: 'company_notification_settings_onboarding',
                        }}
                    >
                        <NotificationChannelSettingsCard
                            setCurrentEditedCard={setCurrentEditedCard}
                            currentEditedCard={currentEditedCard}
                            notificationSettings={company?.notificationSettings || []}
                            admin
                            setLoading={setLoading}
                            companyId={companyId}
                            loading={!company?.notificationSettings || loading}
                            setRequiredUpdateData={setRequiredUpdateData}
                        />
                    </QuickHelpTooltip>
                )
            }
            {
                hasAccess(userRoles.ASSET_MANAGEMENT) && (
                    <BatteryExchangeLocationsCard
                        loading={loading}
                        className={classes.belTable}
                    />
                )
            }
        </>
    );
};

export default Overview;
