import React, {
    useCallback,
    useContext,
    useMemo,
    useRef,
    useEffect,
    SetStateAction,
    Dispatch,
    useState,
} from 'react';
import { useTheme } from '@mui/material/styles';
import ApexCharts, { ApexOptions } from 'apexcharts';
import CustomThemeContext from 'Contexts/CustomThemeContext';
import QuickHelpTooltip from 'Contexts/QuickHelpContext/QuickHelpTooltip';
import { SensorDataItem } from 'dataTypes/SecureBackend/apiResponse';
import useApexChartLocale from 'hooks/useApexChartLocale';
import ReactApexChart from 'react-apexcharts';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { CustomAnnotation } from 'shared-components/dataTypes';
import { generateTooltip } from 'shared-components/ApexTemperatureChart/tooltipDefinition';
import useExcursionSeries from 'shared-components/ApexTemperatureChart/useExcursionSeries';
import icons from 'shared-components/icons';
import { SkycellThemeInterface } from 'themes/skycellThemeInterface';
import { HiddenSeries } from 'TrackAndTrace/GenericShipmentDetails/GenericShipmentDetails';
import useScreenSize from 'hooks/useScreenSize';
import { downloadFileFromString } from 'shared-components/common';
import useClasses from 'hooks/useClasses';
import { dateToNumber, getFormattedDate, nowDateToNumber } from 'utils/timeUtils';
import { CHART_ANNOTATION_COLORS, CHART_GRAPH_COLORS, LOCATION_COLORS, POSITION_TO_COLOR } from '../constants';
import { ApexTooltip, FixedTooltip, SensorLabels } from './lib';
import { downloadIcon } from './icons';
import styles from './ApexTemperatureChart.style';

type Props = {
    csvDownload?: boolean,
    chartLimits: {
        min: number,
        max: number,
    },
    customAnnotations?: CustomAnnotation[],
    dateTimeTemperatureExcursion?: string,
    disableToolbar?: boolean,
    disableXaxisInTooltip?: boolean,
    doorInfoData?: SensorDataItem[],
    doorInfoLabels?: SensorLabels,
    entityId: string,
    excursionRange?: boolean,
    exportPng?: (base64: string, aspectRatio: number) => void,
    fixedTooltip?: FixedTooltip,
    height?: number,
    hiddenSeries?: { [key: string]: boolean },
    inLocalTimeZone?: boolean,
    isAsset?: boolean,
    isLogger?: boolean,
    isPredictedExcursion?: boolean,
    locationHintInTooltip?: boolean,
    locationSeries?: ApexOptions['series'],
    measuredDataLength?: number,
    onRefresh?: () => void,
    serialNumber?: string,
    setChartRef?: (chartRef: React.RefObject<any>) => void,
    setHiddenSeries?: Dispatch<SetStateAction<HiddenSeries>>,
    setMouseMoveDataIndex?: (elementIndex: number, timestamp?: number) => void,
    setZoomedDataLimits?: (limits: { min: number, max: number }) => void,
    shipmentNumber?: string,
    showMap?: boolean,
    showMarkers?: boolean,
    showNowAnnotation?: boolean,
    showTempRange?: boolean,
    temperatureData: SensorDataItem[],
    temperatureLabels?: SensorLabels,
    temperatureRangeMax: number,
    temperatureRangeMin: number,
    tempRangeMinimalisticStyle?: boolean,
    width?: number,
    xMax?: number,
    xMin?: number,
}
const defAspectRatio = 16 / 9;
const ApexTemperatureChart = ({
    chartLimits,
    csvDownload = true,
    customAnnotations = [],
    dateTimeTemperatureExcursion,
    disableToolbar = false,
    disableXaxisInTooltip = false,
    doorInfoData = [],
    doorInfoLabels,
    entityId = null,
    excursionRange = false,
    exportPng = null,
    fixedTooltip = {
        enabled: false,
        offsetX: 0,
        offsetY: 0,
        position: 'topRight',
    },
    height,
    hiddenSeries = null,
    inLocalTimeZone = false,
    isLogger = false,
    isPredictedExcursion = false,
    locationHintInTooltip = false,
    locationSeries = [],
    measuredDataLength,
    onRefresh = null,
    serialNumber = '',
    setChartRef = null,
    setHiddenSeries = null,
    setMouseMoveDataIndex = null,
    setZoomedDataLimits = null,
    shipmentNumber = '',
    showMap = false,
    showMarkers = false,
    showNowAnnotation = true,
    showTempRange = true,
    temperatureData = [],
    temperatureLabels,
    temperatureRangeMax = null,
    temperatureRangeMin = null,
    tempRangeMinimalisticStyle = false,
    width,
    xMax = null,
    xMin = null,
}: Props) => {
    const classes = useClasses(styles);
    const chartRef = useRef(null);
    const chartId = useMemo(() => `chart-${Date.now()}`, []);

    const { t: trans } = useCustomTranslation();
    const { chartLocale } = useApexChartLocale();
    const { theme } = useContext(CustomThemeContext);
    const muiTheme = useTheme<SkycellThemeInterface>();
    const { width: screenWidth } = useScreenSize();
    const [sizeOverride, setSizeOverride] = useState(false);

    const resetZoom = useCallback(() => {
        ApexCharts.exec(chartId, 'resetSeries', {
            shouldResetZoom: true,
            shouldUpdateChart: false,
        });
        if (setZoomedDataLimits) {
            setZoomedDataLimits({ min: null, max: null });
        }
    }, [chartId]);

    useEffect(() => {
        if (chartRef?.current && exportPng) {
            setSizeOverride(true);
            setTimeout(() => {
                chartRef?.current?.chart?.dataURI()?.then((base64) => {
                    exportPng(base64?.imgURI || '', defAspectRatio);
                });
            }, 250);
            setTimeout(() => {
                setSizeOverride(false);
            }, 500);
        }
    }, [chartRef, screenWidth]);
    const beforeZoom = (chartContext, { xaxis }) => {
        if (setZoomedDataLimits) {
            setZoomedDataLimits(xaxis);
        }
    };

    const nowAnnotation = useMemo(() => (showNowAnnotation ? [{
        x: nowDateToNumber(),
        borderColor: '#747474',
        strokeDashArray: 5,
        borderWidth: 1,
        label: {
            style: {
                color: '#747474',
                background: 'none',
            },
            textAnchor: 'start',
            offsetX: -10,
            borderWidth: 0,
            text: trans('SENSOR_DATA.NOW'),
        },
    }] : []), [
        showNowAnnotation,
        temperatureData,
        inLocalTimeZone,
    ]);

    const beforeResetZoom = () => {
        if (onRefresh) {
            onRefresh();
        }
    };

    const temperatureExcursionAnnotation = useMemo(() => (dateTimeTemperatureExcursion ? [{
        x: nowDateToNumber(),
        borderColor: '#D44848',
        strokeDashArray: 0,
        borderWidth: 4,
        label: {
            style: {
                background: 'none',
            },
            textAnchor: 'start',
            borderWidth: 0,
            text: trans('TEMPERATURE_STATUS.EXCURSION'),
        },
    }] : []), [dateTimeTemperatureExcursion, inLocalTimeZone]);

    const customAnnotationsArray = useMemo(() => {
        return customAnnotations.map(annotationData => {
            return {
                x: dateToNumber(annotationData.date, inLocalTimeZone),
                borderColor: '#747474',
                strokeDashArray: 0,
                borderWidth: 2,
                label: {
                    style: {
                        color: '#747474',
                        background: 'none',
                        font: 'normal normal normal 16px/19px Roboto',
                    },
                    textAnchor: annotationData.anchor || 'start',
                    offsetX: annotationData.offsetX || (annotationData.anchor === 'end' ? 1 : -1) * 10,
                    borderWidth: 0,
                    text: annotationData.title,
                },
            };
        });
    }, [customAnnotations, inLocalTimeZone]);

    const colors = useMemo(() => {
        const temperatureColors = temperatureLabels.dataTypes.map((type, index) => CHART_GRAPH_COLORS[index]);
        const locationColors = locationSeries.map((location, index) => LOCATION_COLORS[index]);
        const doorInfoColors = doorInfoLabels.dataTypes.map((type, index) => CHART_ANNOTATION_COLORS[index]);

        const allColors = [
            ...temperatureColors,
            ...locationColors,
            ...doorInfoColors,
        ];

        return allColors.length === 0
            ? CHART_GRAPH_COLORS.at(-1)
            : allColors;
    }, [
        temperatureLabels,
        doorInfoLabels,
        locationSeries,
    ]);

    const doorAnnotations = useMemo(() => {
        if (doorInfoData.length === 0) {
            return [];
        }

        return doorInfoData.reduce((data, { t, d }, rawDataIndex) => {
            if (d.length === 0) {
                return data;
            }

            const items = d.reduce((currentData, value, index) => {
                return value === null || (rawDataIndex > 0 && doorInfoData[rawDataIndex - 1].d[index] === value)
                    ? currentData
                    : [
                        ...currentData,
                        {
                            x: dateToNumber(t, inLocalTimeZone),
                            borderColor: CHART_ANNOTATION_COLORS[index],
                            strokeDashArray: 0,
                            borderWidth: 4,
                            label: {
                                style: {
                                    background: 'white',
                                    color: CHART_ANNOTATION_COLORS[index],
                                    position: 'front',
                                    font: 'normal normal normal 16px/19px Roboto',
                                },
                                offsetX: 10,
                                offsetY: value === 'OPEN' ? 10 : 30,
                                orientation: 'horizontal',
                                textAnchor: 'start',
                                borderWidth: 1,
                                borderColor: CHART_ANNOTATION_COLORS[index],
                                text: value === 'OPEN' ? trans('SENSOR_DATA.DOOR_OPENED')
                                    : trans('SENSOR_DATA.DOOR_CLOSED'),
                            },
                        },
                    ];
            }, []);

            return data.concat(items);
        }, []);
    }, [doorInfoData, inLocalTimeZone]);

    const temperatureRange = useMemo(() => {
        if (!showTempRange) {
            return [];
        }

        return [
            temperatureRangeMin
                ? {
                    y: temperatureRangeMin,
                    borderColor: '#EDAE49',
                    strokeDashArray: tempRangeMinimalisticStyle ? 0 : 3,
                    borderWidth: tempRangeMinimalisticStyle ? 1 : 3,
                    label: {
                        style: {
                            fontSize: tempRangeMinimalisticStyle ? '10px' : '15px',
                            color: '#EDAE49',
                            background: 'none',
                        },
                        offsetX: -10,
                        offsetY: 18,
                        textAnchor: 'end',
                        borderWidth: 0,
                        text: 'Min',
                    },
                }
                : null,
            temperatureRangeMax
                ? {
                    y: temperatureRangeMax,
                    borderColor: '#EDAE49',
                    strokeDashArray: tempRangeMinimalisticStyle ? 0 : 3,
                    borderWidth: tempRangeMinimalisticStyle ? 1 : 3,
                    label: {
                        style: {
                            fontSize: tempRangeMinimalisticStyle ? '10px' : '15px',
                            color: '#EDAE49',
                            background: 'none',
                        },
                        offsetX: -10,
                        textAnchor: 'end',
                        borderWidth: 0,
                        text: 'Max',
                    },
                }
                : null,
        ].filter(item => item !== null);
    }, [
        showTempRange,
        temperatureRangeMin,
        temperatureRangeMax,
    ]);

    const markerOptions = useMemo(() => {
        const locationWidth = tempRangeMinimalisticStyle ? 0 : 0;

        return {
            size: [...temperatureLabels.dataTypes.map(() => (showMarkers ? 3 : 0)),
                ...locationSeries.map(() => locationWidth)],
            strokeWidth: [...temperatureLabels.dataTypes.map(() => (temperatureData?.length >= 200 ? 0 : 1)),
                ...locationSeries.map(() => locationWidth)],
        };
    }, [
        showMarkers,
        locationSeries,
        temperatureLabels,
        tempRangeMinimalisticStyle,
    ]);

    useEffect(() => {
        if (setChartRef && chartRef?.current) {
            setChartRef(chartRef?.current?.chartRef);
        }
    }, [chartRef?.current]);

    const xaxisCategories = useMemo(() => {
        return temperatureData.map(({ t }) => dateToNumber(t, false));
    }, [temperatureData]);

    const excursionRangeSeries = useExcursionSeries({
        temperatureRangeMax,
        temperatureRangeMin,
        temperatureData,
        enabled: excursionRange,
        tempIndex: temperatureLabels?.positions?.indexOf('INTERNAL') || 0,
    });

    const series = useMemo((): any[] => {
        const {
            positions = [],
        } = temperatureLabels;

        if (temperatureData.length === 0 || positions.length === 0) {
            return [{ data: [] }];
        }

        const legendData = positions.map((position) => {
            if (position === 'UNSPECIFIED') {
                return trans('SENSOR_DATA.TEMPERATURE_IN_C');
            }

            return `${trans(`LANE_MANAGEMENT.${position === 'INTERNAL'
                ? 'INTERNAL_TEMPERATURE_IN_C' : 'AMBIENT_TEMPERATURE_IN_C'}`)}`;
        }) || [];

        if (isPredictedExcursion) {
            const splitIndex = measuredDataLength + 1 || 0;
            const lineCartData: ApexOptions['series'] = legendData.flatMap((name, i) => {
                // The first part of the series
                const data1 = temperatureData.slice(0, splitIndex + 1).filter(({ d }) => d[i] !== null)
                    .map(({ d }, dI) => ({
                        x: xaxisCategories[dI],
                        y: d[i],
                    }));

                // The second part of the series
                const data2 = temperatureData.slice(splitIndex - 1)
                    .filter(({ d }) => d[i] !== null).map(({ d }, dI) => ({
                        x: xaxisCategories[dI + splitIndex],
                        y: d[i],
                    }));

                // TODO add ascending sort
                return [
                    {
                        name: `Predicted ${name}`,
                        data: data2,
                        type: 'rangeArea',
                        color: POSITION_TO_COLOR[positions[i]],
                        position: positions[i],
                        opacity: 1,
                        stroke: {
                            width: 5,
                            dashArray: 3,
                            curve: 'stepline',
                        },
                    },
                    {
                        name: `${name}`,
                        data: data1,
                        type: 'rangeArea',
                        color: POSITION_TO_COLOR[positions[i]],
                        position: positions[i],
                        opacity: 1,
                        stroke: {
                            width: 2,
                            curve: 'straight',
                        },
                    },
                ];
            });

            return [...lineCartData, ...locationSeries, ...excursionRangeSeries];
        } else {
            const lineCartData: ApexOptions['series'] = legendData.map((name, i) => {
                const data = temperatureData.map(({ d }, dI) => ({
                    x: xaxisCategories[dI],
                    y: d[i] || null,
                })).filter(({ y }) => y !== null);

                return {
                    name,
                    data,
                    type: 'rangeArea',
                    color: POSITION_TO_COLOR[positions[i]],
                    position: positions[i],
                    opacity: 1,
                    stroke: {
                        width: 2,
                        curve: 'straight',
                    },
                };
            });

            return [...lineCartData, ...locationSeries, ...excursionRangeSeries];
        }
    }, [
        temperatureData,
        temperatureLabels,
        excursionRangeSeries,
        locationSeries,
        xaxisCategories,
        measuredDataLength,
    ]);

    useEffect(() => {
        if (chartRef?.current && hiddenSeries) {
            Object.keys(hiddenSeries).forEach((position) => {
                const seriesName = series?.find((s) => s.position === position)?.name;
                const {
                    globals,
                } = chartRef?.current?.chart?.w || {};
                const {
                    seriesNames,
                    collapsedSeries,
                } = globals || {};

                const seriesIndex = seriesNames?.indexOf(seriesName);
                const wasCollapsed = collapsedSeries?.some(it => it.index === seriesIndex);

                if (!seriesName) return;
                if (wasCollapsed === hiddenSeries[position]) return;
                if (seriesNames.length - collapsedSeries.length === 1 && !wasCollapsed) return;
                chartRef?.current?.chart?.toggleSeries(seriesName);
            });
        }
    }, [chartRef?.current, hiddenSeries, series]);

    const customTooltip = useCallback((opts) => generateTooltip(
        {
            theme: muiTheme,
            trans,
            locationHintInTooltip,
            opts,
            isUTC: !inLocalTimeZone,
            series,
        },
    ), [muiTheme, trans, locationHintInTooltip, inLocalTimeZone, series]);

    const onCsvDownload = useCallback(() => {
        if (csvDownload) {
            const headers = `Date and time${ (!inLocalTimeZone) ? ' (UTC)' : ''}, ${Object.values(series)
                .map((it) => it.name).join(',')}`;
            const csvRows: string[] = [headers.replace('/[^\x00-\x7F]/g', '')];

            for (let i = 0; i < series.length; i++) {
                xaxisCategories.forEach((x, index) => {
                    if (csvRows[index + 1] === undefined) {
                        const dateTimeString = getFormattedDate(x, inLocalTimeZone);

                        csvRows[index + 1] = `${dateTimeString},`;
                    }
                    const currentCell = series[i].data.find(it => it.x === x);
                    const isGateway = series[i].position === 'GATEWAY';
                    const dataInCell = currentCell
                        ? (isGateway ? !!currentCell.y : (currentCell?.y?.[0] || currentCell?.y))
                        : null;

                    csvRows[index + 1] += `${dataInCell || null}${i === series.length - 1 ? '' : ','}`;
                });
            }

            downloadFileFromString(
                csvRows.join('\n'),
                `temp_report${shipmentNumber !== '' ? `_${shipmentNumber}` : ''}_${serialNumber}.csv`,
            );
        }
    }, [csvDownload, series, inLocalTimeZone]);

    const customTools = useMemo<ApexTooltip[]>(() => {
        const tools: ApexTooltip[] = [];

        if (onRefresh) {
            tools.push({
                icon: `<img src="${icons.refresh}" width="17" alt="refresh"/>`,
                index: -6,
                class: classes.refreshIcon,
                title: trans('SENSOR_DATA.REFRESH_DATA'),
                click() {
                    onRefresh();
                },
            });
        }
        if (csvDownload) {
            tools.push({
                icon: downloadIcon,
                index: 0,
                class: classes.downloadIcon,
                title: trans('CHART.EXPORT_TO_CSV'),
                click() {
                    onCsvDownload();
                },
            });
        }

        return tools;
    }, [onRefresh, csvDownload, onCsvDownload]);

    const mouseMoveEvent = useCallback((e, chartContext, { dataPointIndex = -1, config, seriesIndex }) => {
        if (dataPointIndex === -1) return;
        const timestamp = config?.series?.[seriesIndex]?.data?.[dataPointIndex]?.x;

        if (setMouseMoveDataIndex && dataPointIndex !== -1 && timestamp) {
            setMouseMoveDataIndex(dataPointIndex, timestamp || null);
        }
    }, [setMouseMoveDataIndex]);

    const options = useMemo((): ApexOptions => {
        return {
            chart: {
                id: chartId,
                type: 'rangeArea',
                toolbar: {
                    show: !disableToolbar,
                    autoSelected: 'zoom',
                    tools: {
                        customIcons: customTools,
                        download: false,
                        reset: false,
                    },
                },
                locales: chartLocale,
                animations: {
                    enabled: false,
                    dynamicAnimation: {
                        enabled: false,
                    },
                    animateGradually: {
                        enabled: false,
                    },

                },
                fontFamily: 'Roboto, serif',
                events: {
                    beforeZoom,
                    zoomed: beforeZoom,
                    beforeResetZoom,
                    mouseMove: mouseMoveEvent,
                    legendClick(chart: any, seriesIndex?: number, options?: any) {
                        const {
                            globals,
                        } = options;
                        const {
                            seriesNames,
                            collapsedSeries,
                        } = globals;

                        const wasCollapsed = collapsedSeries?.some(it => it.index === seriesIndex);

                        if (!(collapsedSeries?.length === seriesNames.length - 1 && !wasCollapsed)) {
                            const seriesPosition = series[seriesIndex].position;

                            chart.toggleSeries(seriesNames[seriesIndex]);
                            if (hiddenSeries && setHiddenSeries && hiddenSeries[seriesPosition] !== undefined) {
                                setHiddenSeries((prev) => ({
                                    ...prev,
                                    [seriesPosition]: !wasCollapsed,
                                }));
                            }
                        }
                    },
                },
            },
            fill: {
                type: 'solid',
                opacity: series.map(it => it.opacity || 1),
            },
            stroke: {
                width: series.map(it => it?.stroke?.width || 2),
                curve: series.map(it => it?.stroke?.curve || 'straight'),
                dashArray: series.map(it => it?.stroke?.dashArray || 0),
            },
            dataLabels: {
                enabled: false,
            },
            markers: {
                size: 1,
                shape: 'circle',
                ...markerOptions,
            },
            theme: {
                mode: theme === 'default' ? 'light' : 'dark',
            },
            // @ts-ignore
            colors,
            annotations: {
                yaxis: [
                    ...temperatureRange,
                ],
                xaxis: [
                    ...temperatureExcursionAnnotation,
                    ...nowAnnotation,
                    ...doorAnnotations,
                    ...customAnnotationsArray,
                ],
            },
            tooltip: {
                fixed: fixedTooltip,
                shared: true,
                followCursor: true,
                cssClass: classes.tooltip,
                custom: customTooltip,
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: !inLocalTimeZone,
                },
                tooltip: {
                    enabled: false,
                },
                categories: xaxisCategories,
                min: xMin || xaxisCategories[0],
                max: xMax || xaxisCategories.at(-1),
            },
            yaxis: {
                min: chartLimits.min,
                max: chartLimits.max,
            },
            legend: {
                show: isLogger || !temperatureLabels.positions.includes('UNSPECIFIED') || locationSeries.length > 0,
                position: 'top',
                offsetY: 30,
                horizontalAlign: 'center',
                showForSingleSeries: true,
                onItemClick: {
                    toggleDataSeries: false,
                },
            },
            showMap,
        };
    }, [
        chartId,
        chartLimits,
        markerOptions,
        temperatureRange,
        xaxisCategories,
        temperatureExcursionAnnotation,
        nowAnnotation,
        doorAnnotations,
        colors,
        theme,
        muiTheme,
        inLocalTimeZone,
        chartLocale,
        disableXaxisInTooltip,
        locationSeries,
        mouseMoveEvent,
        series,
        customTooltip,
        xMin,
        xMax,
    ]);

    return (
        <QuickHelpTooltip
            tooltipInfo={{
                text: trans('QUICK_HELP.SENSOR_DATA.EXPORT_CHART'),
                customRectSize: [csvDownload ? 72 : 48, 20],
                offsetPx: [-7, 4],
                childOffsetPercent: [100, 0],
                position: 'auto',
                uid: `$exportChartButton_${entityId}`,
            }}
        >
            <div
                onContextMenu={(event) => {
                    event.preventDefault();
                    resetZoom();
                }}
                className={sizeOverride ? classes.sizeOverrideContainer : ''}
            >
                <ReactApexChart
                    ref={chartRef}
                    type="rangeArea"
                    {...(width ? { width } : {})}
                    {...(height ? { height } : {})}
                    {...(sizeOverride ? { width: 600 * defAspectRatio } : {})}
                    {...(sizeOverride ? { height: 600 } : {})}
                    options={options}
                    series={series}
                />
            </div>
        </QuickHelpTooltip>
    );
};

export default ApexTemperatureChart;
