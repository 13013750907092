import React, {
    createContext,
    useContext,
    useState,
    ReactChild, useCallback, useEffect,
} from 'react';
import { Company } from 'dataTypes/SecureBackend/apiResponse';
import { AuthContext, UserInfo, initialUserInfo } from 'Contexts/AuthContext';
import useSecureBackendEndpoints from 'hooks/useSecureBackendEndpoints/index';

const companyInitialState: Company = {
    address: {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        country: {
            code: '',
            name: '',
        },
        geolocation: {
            latitude: null,
            longitude: null,
        },
        zipCode: '',
    },
    companyType: null,
    companyRoles: [],
    energyLevelThreshold: null,
    contactInfo: {
        contactPerson: '',
        email: '',
        phoneNumber: '',
    },
    id: null,
    logoImagePath: '',
    name: 'No company info',
    website: '',
    serviceProviderCompanies: [],
};

export interface CurrentUserContextInterface {
    company: Company,
    companyInitialState: Company,
    logout: () => void,
    setCompany: (company: Company) => void,
    userInfo: UserInfo,
    notificationsCount: number,
    updateNotificationsCount: Function,
}

export const CurrentUserContext = createContext<CurrentUserContextInterface>({
    company: companyInitialState,
    companyInitialState,
    logout: () => {},
    setCompany: null,
    userInfo: initialUserInfo,
    notificationsCount: 0,
    updateNotificationsCount: () => {},

});

type Props = {
    children: ReactChild,
}

const CurrentUserProvider = ({ children }: Props) => {
    const { userInfo, logout } = useContext(AuthContext);
    const [company, setCompany] = useState<Company>(companyInitialState);
    const { FlexibleRequest: getNotificationCount } = useSecureBackendEndpoints('notifications/count/unread').requests;
    const [shouldUpdate, setShouldUpdate] = useState(Date.now());
    const [notificationsCount, setNotificationsCount] = useState(0);
    const updateNotificationsCount = useCallback(() => setShouldUpdate(Date.now()), []);

    useEffect(() => {
        (async () => {
            try {
                const { value = 0 } = (await getNotificationCount('GET')).data || {};

                setNotificationsCount(value);
            } catch (error) {
                global.console.log(error);
            }
        })();
    }, [shouldUpdate]);

    return (
        <CurrentUserContext.Provider
            value={{
                company,
                companyInitialState,
                logout,
                setCompany,
                userInfo,
                notificationsCount,
                updateNotificationsCount,
            }}
        >
            { children }
        </CurrentUserContext.Provider>
    );
};

export default CurrentUserProvider;
