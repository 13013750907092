import { UserInfo, ExtendedProfile } from './dataTypes';

export const initialExtendedProfile: ExtendedProfile = {
    assignedRoles: [],
    attributes: {
        companyId: [],
    },
    username: '',
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: null,
};

export const initialUserInfo: UserInfo = {
    assignedRoles: [],
    companyId: null,
    name: '',
    email: '',
};

export const getUserInfo = (extendedProfile: ExtendedProfile): UserInfo => {
    const {
        assignedRoles = [],
        attributes,
        email,
        name,
    } = extendedProfile;
    const { companyId: compIdArr = [] } = attributes;
    const [companyId = null] = compIdArr;

    return {
        assignedRoles,
        companyId,
        email,
        name,
    };
};
