import { Collapse } from '@mui/material';
import useClasses from 'hooks/useClasses';
import useGetTranslationGroup from 'hooks/useGetTranslationGroup';
import React, { useCallback, useMemo, useState } from 'react';
import useCustomTranslation from 'hooks/useCustomTranslation';
import { useNavigate } from 'react-router-dom';
import TempRange from 'shared-components/TempRange';
import PackagingInfo from
    'TrackAndTrace/GenericShipments/components/ShipmentsMap/components/TooltipCluster/ClusterBodyItem/PackagingInfo';
import { ActiveShipment } from 'TrackAndTrace/GenericShipments/components/ShipmentsMap/ShipmentsMap';
import { GenericShipmentData } from 'TrackAndTrace/GenericShipments/lib';
import AirportsInfo from 'TrackAndTrace/Tooltips/ShipmentTooltip/TooltipBody/AirportsInfo';
import { EnergyLevelStatus } from 'TrackAndTrace/lib';
import {
    styles,
} from './ClusterBodyItem.style';
import PackagingsBoxVisualization from './PackagingsBoxVisualization/PackagingsBoxVisualization';

type Props = {
    shipment: GenericShipmentData,
    activeShipment: ActiveShipment,
    setActiveShipment: Function,
}

export const TITLE_HEIGHT = 95;
const ClusterBodyItem = ({
    shipment,
    activeShipment,
    setActiveShipment,
}: Props) => {
    const classes = useClasses(styles);
    const packagingCodeLabels = useGetTranslationGroup('PACKAGING_CODE_LABEL');
    const { t } = useCustomTranslation();
    const navigate = useNavigate();
    const [packagingInfoOpen, setPackagingInfoOpen] = useState([]);
    const bodyOpen = useMemo(() => activeShipment?.externalId === shipment?.externalId, [
        activeShipment?.externalId,
        shipment?.externalId,
    ]);

    const temperatureCheckStatus = useMemo<string[]>(
        () => shipment.packagings.map(it => it?.temperatureCheckResult?.temperatureStatus),
        [shipment?.packagings],
    );

    const handleShipmentTitleEnter = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveShipment(({ externalId }) => ({
            externalId: bodyOpen && externalId !== shipment?.externalId ? null : shipment?.externalId,
            timestamp: Date.now(),
        }));
    }, [
        bodyOpen,
    ]);

    const toShipment = useCallback(() => {
        navigate(`/track-and-trace/generic-shipments/${shipment.id}`);
    }, []);
    const togglePackagingInfo = useCallback((e, serialNumber) => {
        e.stopPropagation();
        setPackagingInfoOpen(prev => (prev.includes(serialNumber)
            ? prev.filter(it => it !== serialNumber) : [...prev, serialNumber]));
    }, []);

    const packagingTypesInfo = useMemo(() => {
        if (shipment.packagings.length === 0) {
            return {
                element: t('TRACK_AND_TRACE.PLANNED_SHIPMENT'),
                hint: t('TRACK_AND_TRACE.PLANNED_SHIPMENT'),
            };
        }

        return {
            element: Object.keys(shipment.packagingTypesCount)
                .map((type, i, array) => (
                    <span className={classes.packagings} key={`packagingTypesInfo-${i}-${type}`}>
                        <span style={{ fontWeight: 'bold' }}>{shipment.packagingTypesCount[type]}</span>
                        <span> x </span>
                        <span>{packagingCodeLabels[type] || type}</span>
                        {
                            i !== array.length - 1 && <span>, </span>
                        }
                    </span>
                )),
            hint: Object.keys(shipment.packagingTypesCount)
                .map((type) => `${shipment.packagingTypesCount[type]} x ${packagingCodeLabels[type] || type}`)
                .join(', '),
        };
    }, [shipment, packagingCodeLabels]);
    const stopPropagation = useCallback((e) => e.stopPropagation(), []);

    return (
        <div className={classes.wrapper}>
            <div
                className={classes.header}
                onMouseEnter={handleShipmentTitleEnter}
                onClick={toShipment}
            >
                <div className={[
                    classes.title,
                ].join(' ')}
                >
                    {
                        shipment?.externalId
                    }
                </div>
                <div className={classes.description}>
                    {
                        temperatureCheckStatus.length !== 0 && (
                            <>
                                <div className={classes.descriptionInfo}>
                                    {
                                        temperatureCheckStatus.length !== 0 && (
                                            <PackagingsBoxVisualization statuses={
                                                shipment?.externalId === 'CT-023321'
                                                    ? ['PREDICTED_EXCURSION', 'NO_DATA', 'NO_DATA']
                                                    : temperatureCheckStatus
                                            }
                                            />
                                        )
                                    }
                                </div>
                                <div className={classes.packagingsText}>
                                    {packagingTypesInfo.element}
                                </div>
                            </>
                        )
                    }
                    {
                        shipment.packagingSquareIcons.length === 0
                        && <div className={classes.planned}>{t('TRACK_AND_TRACE.PLANNED_SHIPMENT')}</div>
                    }
                </div>
            </div>
            <Collapse className={classes.body} in={bodyOpen}>
                <div onClick={stopPropagation}>
                    <div className={classes.division}>
                        <div className={classes.shortInfo}>
                            <TempRange
                                tempRange={shipment.temperatureRange} tooltip={{
                                    description: t('EXPLANATION_TOOLTIPS.TEMPERATURE_RANGE'),
                                    placement: 'right',
                                }}
                            />
                            <AirportsInfo
                                destinationAirportCode={shipment.destinationAirport}
                                originAirportCode={shipment.originAirport}
                                small
                                classes={{
                                    root: classes.customAirports,
                                    headerInfoGroup: classes.headerInfoGroup,
                                }}
                            />
                        </div>
                    </div>
                    {
                        shipment.cargo.map((cargo, index) => {
                            const { packaging = '' } = cargo || {};
                            const { serialNumber = '' } = packaging || {};
                            const { energyLevelCheckResult } = cargo || {};

                            return (
                                <PackagingInfo
                                    key={`packaging-${index}-${serialNumber}`}
                                    cargo={cargo}
                                    togglePackagingInfo={togglePackagingInfo}
                                    packagingInfoOpen={packagingInfoOpen}
                                    breach={cargo
                                        ?.energyLevelCheckResult
                                        ?.energyLevelStatus === EnergyLevelStatus.ENERGY_LEVEL_BREACH}
                                    energyLevel={energyLevelCheckResult?.remainingEnergyLevel}
                                />
                            );
                        })
                    }
                </div>
            </Collapse>
        </div>
    );
};

export default ClusterBodyItem;
